import { EGallery, IArtwork } from '../types';
import affairs from '../images/galerielennie/affairs.jpg';
import aureum from '../images/galerielennie/aureum.jpg';
import braStrap from '../images/galerielennie/braStrap.jpg';
import deadline from '../images/galerielennie/deadline.jpg';
import deepBlack from '../images/galerielennie/deepBlack.jpg';
import homerKikkert from '../images/galerielennie/homerKikkert.jpg';
import itWontFit from '../images/galerielennie/itWontFit.jpg';
import kat441121 from '../images/galerielennie/kat441121.jpg';
import livingRoomVisitors from '../images/galerielennie/livingRoomVisitors.jpg';
import lostinSpace4 from '../images/galerielennie/lostinSpace4.jpg';
import sanWyfkes from '../images/galerielennie/sanWyfkes.jpg';
import spirit4 from '../images/galerielennie/spirit4.jpg';
import steenkistWerk1 from '../images/galerielennie/steenkistWerk1.jpg';
import stuyvesant from '../images/galerielennie/stuyvesant.jpg';
import vaderDraagtZoon from '../images/galerielennie/vaderDraagtZoon.jpg';

import montBlanc8 from '../images/artcatch/1arakcheevaMontBlanc8.jpg';
import montBlanc1 from '../images/artcatch/2arakcheevaMontBlanc1.jpg';
import crocodileHorizon from '../images/artcatch/3crocodileBeyondTheHorizon.jpg';
import humizovo from '../images/artcatch/4dunaevaHumizovo.jpg';
import verlatenEiland from '../images/artcatch/5ermolenkoHetVerlatenEiland1.jpg';
import beingTime from '../images/artcatch/6korzhovBeingTime.jpg';
import Messedamm from '../images/artcatch/7kovalenkoUnterfuhrungamAmMessedamm.jpg';
import polaroidDromen from '../images/artcatch/8ksutaPolaroidDromen2.jpg';
import pass3 from '../images/artcatch/10onischenkoPass3.jpg';
import mist2 from '../images/artcatch/11romanovaMistII.jpg';
import venus from '../images/artcatch/12SamoylovaVenus.jpg';
import vassilievUntitled from '../images/artcatch/13vassilievUntitled.jpg';
import noon from '../images/artcatch/14velichkoNoon.jpg';
import yakovlevaUntitled from '../images/artcatch/15yakovlevaUntitled.jpg';
import lotaSamara from '../images/artcatch/LotaLotaSamara.jpg';

export const artworks: IArtwork[] = [
    {
        id: 0,
        lotNumber: 1,
        title: 'Sân Wyfkes',
        artist: 'Gerke Procee',
        gallery: EGallery.GalerieLennie,
        image: sanWyfkes,
        startingBid: 880,
        description:
            'Gerke is al in 2016 begonnen aan Sân Wyfkes, maar heeft het toen aan de kant gezet met de intentie om het later af te maken. Hij heeft het werk toen ergens opgeborgen en vond het in 2021 weer terug. Hij voelde toen direct de urgentie om Sân Wyfkes de inhoud en perceptie mee te geven die hij voor ogen had. In de tijd dat hij het had opgeborgen heeft Gerke er waarschijnlijk onbewust over nagedacht hoe hij dit werk de juiste vorm en uitstraling kon geven. Het waren eerst wat abstracte vormen die uiteindelijk zeven vrouwelijke figuren zijn geworden.',
        artistDescription: `Gerke Procee (1993) woont in Friesland. Hij is in 2020 afgestudeerd voor Autonome Beeldende Kunst aan de Academie Minerva in Groningen.Door een onderdompeling in een filosofisch doolhof van archetypen, griezeligheid en het unheimliche, is Gerke veel van zijn diepste angsten en fantasieën gaan herkennen. Gerke's werk is een creatieve explosie waarin hij nooit ontwikkelde creaties onderzoekt. Deze legt hij vervolgens vast op digitaal en fysiek canvas.`,
        dimensions: '100 x 100 cm',
        details: 'Acrylverf op canvas',
    },
    {
        id: 1,
        lotNumber: 2,
        title: 'Homer de kikkert slikkert',
        artist: 'Gerke Procee',
        gallery: EGallery.GalerieLennie,
        image: homerKikkert,
        startingBid: 700,
        description:
            'Gerke is al in 2016 begonnen aan Sân Wyfkes, maar heeft het toen aan de kant gezet met de intentie om het later af te maken. Hij heeft het werk toen ergens opgeborgen en vond het in 2021 weer terug. Hij voelde toen direct de urgentie om Sân Wyfkes de inhoud en perceptie mee te geven die hij voor ogen had. In de tijd dat hij het had opgeborgen heeft Gerke er waarschijnlijk onbewust over nagedacht hoe hij dit werk de juiste vorm en uitstraling kon geven. Het waren eerst wat abstracte vormen die uiteindelijk zeven vrouwelijke figuren zijn geworden.',
        artistDescription: `Gerke Procee (1993) woont in Friesland. Hij is in 2020 afgestudeerd voor Autonome Beeldende Kunst aan de Academie Minerva in Groningen.Door een onderdompeling in een filosofisch doolhof van archetypen, griezeligheid en het unheimliche, is Gerke veel van zijn diepste angsten en fantasieën gaan herkennen. Gerke's werk is een creatieve explosie waarin hij nooit ontwikkelde creaties onderzoekt. Deze legt hij vervolgens vast op digitaal en fysiek canvas.`,
        dimensions: '69 x 66 cm',
        details: 'Acrylverf op houten paneel ',
    },
    {
        id: 2,
        lotNumber: 3,
        title: 'Lost in space 4',
        artist: 'Jacobien de Korte',
        gallery: EGallery.GalerieLennie,
        image: lostinSpace4,
        startingBid: 700,
        description: 'Eenzaamheid in een stedelijke omgeving.',
        artistDescription:
            'Jacobien de Korte (1969) heeft grafische vormgeving gestudeerd aan de Academie van beeldende kunsten in Rotterdam (Willem de Kooning). Haar autonoom werk ontstaat vanuit persoonlijke gevoelens en ervaringen. Op verstilde, introverte wijze tracht ze begrippen uit te drukken als vervreemding, eenzaamheid, mysterie en melancholie. Deze zijn voor iedereen in meer of mindere mate herkenbaar. Met het streven naar wederzijdse herkenbaarheid hoopt ze troost en begrip te bieden en te vinden. Het printen van het werk op fine art papier is hierbij een belangrijk onderdeel van het proces.',
        dimensions: '90 x 60 cm',
        details: 'Fotografie op Dibond',
    },
    {
        id: 3,
        lotNumber: 4,
        title: 'Werk 1 ',
        artist: 'Jesse Steenkist',
        gallery: EGallery.GalerieLennie,
        image: steenkistWerk1,
        startingBid: 340,
        description:
            'Een doorzichtige plastic lag in de vuilnisbak bij iemand voor de deur. De plastic zak zat in een vuilniszak, samen in elkaar. Het touwtje van de vuilniszak en de plastic zak zijn ook samen gelamineerd. Locatie van de vondst: wijk in Amsterdam',
        artistDescription:
            'Jesse Steenkist woont en werkt in Utrecht. Jesse heeft een fascinatie voor het alledaagse. Haar oog valt op felle kleuren en vormen tijdens een wandeling/fietsroute. Iets onnatuurlijks in de natuur. De manier waarop ik werk voelt als een spel van bouwen en wegnemen. De kleinste verandering van vorm of kleur kan de totaliteit van het werk veranderen. Het proces ziet ze als een reis waarin ze het werk meeneemt. Soms is het een korte reis waarin elke beweging past bij de volgende en soms is het een complexe reis van veranderingen en "verkeerde" bewegingen.',
        dimensions: '30 x 30 cm',
        details: 'Hout, Plastic, Papier , Textiel, Draad, Verf',
    },
    {
        id: 4,
        lotNumber: 5,
        title: 'DEEP BLACK',
        artist: 'Florencia Rojas',
        gallery: EGallery.GalerieLennie,
        image: deepBlack,
        startingBid: 460,
        description: '',
        artistDescription: `Florencia Rojas is de kunstenaar achter het merk 'Canach'. Dit merk creëert gestuele hedendaagse kunst met een opvallend gedurfd karakter. Florencia poogt zich door middel van haar werk volledig uit te drukken zonder enig oordeel. Ze herschept haar gevoelens en creatieve voorstellingen ontsproten aan haar fantasierijke geest. De werken van Florencia hebben geen minimalistische maar veeleer een 'maximalistische' expressie. Ze legt de nadruk op het creëren van verschillende dimensies en doet dat altijd met monochromatische kleurtinten. Als gevolg hiervan kan de kijker vormen en schaduwen verkennen die steeds veranderen afhankelijk van verlichting, perspectief of iemands stemming en perceptie.`,
        dimensions: '60 x 80 cm',
        details: 'Zwart acryl geschilderd op canvas',
    },
    {
        id: 5,
        lotNumber: 6,
        title: 'Kat 441121',
        artist: 'Annelise Hewitt',
        gallery: EGallery.GalerieLennie,
        image: kat441121,
        startingBid: 400,
        description:
            'Dit kunstwerk is gemaakt van reclamemateriaal met een kat uit de jaren 80.',
        artistDescription:
            'Annelise Hewitt is een opkomende Afro-Amerikaanse beeldend kunstenaar. Sinds 2018 is ze professioneel actief zowel in Nederland als in de Verenigde Staten. Annelise behaalde een bachelordiploma aan het Williams College (Williamstown, Massachusetts, US) en ontving later een masterdiploma van de Vrije Universiteit in Amsterdam. Annelise woont en werkt sinds 2016 voornamelijk in Nederland en spreekt de Nederlandse taal onderhand vloeiend. Hewitt maakt al haar kunst met de hand en uitsluitend van afgedankte materialen. Duurzaamheid is een belangrijk kenmerk in al haar werk. Dit komt tot uiting door het gebruik van afgedankte of in onbruik geraakte reisgidsen, tijdschriften en andere recyclebare materialen.',
        dimensions: '40 x 60 cm',
        details: 'Papier en acryl op papier',
    },
    {
        id: 6,
        lotNumber: 7,
        title: 'Stuyvesant 44451',
        artist: 'Annelise Hewitt',
        gallery: EGallery.GalerieLennie,
        image: stuyvesant,
        startingBid: 400,
        description:
            'Dit kunstwerk is gemaakt van Peter Stuyvesant reclamemateriaal uit de jaren 80.',
        artistDescription:
            'Annelise Hewitt is een opkomende Afro-Amerikaanse beeldend kunstenaar. Sinds 2018 is ze professioneel actief zowel in Nederland als in de Verenigde Staten. Annelise behaalde een bachelordiploma aan het Williams College (Williamstown, Massachusetts, US) en ontving later een masterdiploma van de Vrije Universiteit in Amsterdam. Annelise woont en werkt sinds 2016 voornamelijk in Nederland en spreekt de Nederlandse taal onderhand vloeiend. Hewitt maakt al haar kunst met de hand en uitsluitend van afgedankte materialen. Duurzaamheid is een belangrijk kenmerk in al haar werk. Dit komt tot uiting door het gebruik van afgedankte of in onbruik geraakte reisgidsen, tijdschriften en andere recyclebare materialen.',
        dimensions: '40 x 60 cm',
        details: 'Papier, chirurgische mondkapjes en acryl op papier',
    },
    {
        id: 7,
        lotNumber: 8,
        title: 'Aureum',
        artist: 'Iris van der Harst',
        gallery: EGallery.GalerieLennie,
        image: aureum,
        startingBid: 520,
        description: '',
        artistDescription:
            'Iris van der Harst (1993) is een kunstschilder uit het noorden van Nederland die met olieverf de grenzen opzoekt binnen het zelfportretteren en creëren van stillevens. Het eren van de stilte van alledaagse momenten staat centraal, waarbij elementen van vervreemding, isolatie, intimiteit, kijken en bekeken worden het meest spreken. Nu meer dan ooit zijn we als mens aangewezen op onze capaciteit om te kunnen leven in isolatie. Met het aannemen van verschillende rollen - aan beide kanten van het doek - creëert Iris continu nieuwe perspectieven waarmee ze een werk op intrigerende wijze gestalte geeft.',
        dimensions: '40 x 50 cm',
        details: 'Olieverf op canvasboard',
    },
    {
        id: 8,
        lotNumber: 9,
        title: 'Deadline',
        artist: `Kamilė Česnavičiūtė`,
        gallery: EGallery.GalerieLennie,
        image: deadline,
        startingBid: 100,
        description:
            'Het is een late avond. Buiten is het donker en binnen is er gedimd werklicht. Steeds als je denkt dat je klaar bent besef je dat je nog net iets meer werk te doen hebt. Tot daadwerkelijk het laatste moment aanbreekt. Iets leid je af…',
        artistDescription: `De in Litouwen geboren kunstenares Kamilė Česnavičiūtė (1996) woont en werkt in Nederland. Kamilė studeerde na de middelbare school beeldende kunst aan de kunstacademie Minerva te Groningen. Na haar afstuderen legde Kamilė zich toe op het schilderen.Haar jonge carrière kent reeds een aantal bijzondere prestaties. In 2019 behoorde ze tot de 'Best of Graduates' tentoongesteld bij galerie Ron Mandos te Amsterdam. Vervolgens verkreeg ze een residentie in Het Resort. Ze won de Sluijters prijs voor schilderen en nam deel aan diverse groepstentoonstellingen en kunstenaarssamenwerkingen, zoals het corona-geïnspireerde online kunst- en muziekplatform @homescreen._ op Instagram.`,
        dimensions: '73 x 103 cm',
        details: 'Acryl op canvas ',
    },
    {
        id: 9,
        lotNumber: 10,
        title: 'Living room visitors',
        artist: `Kamilė Česnavičiūtė`,
        gallery: EGallery.GalerieLennie,
        image: livingRoomVisitors,
        startingBid: 100,
        description:
            'Gezichten en handen zijn één van mijn favoriete dingen om te tekenen en te schilderen wanneer ik nieuwe onderwerpen probeer te bedenken. In dit werk komen ze op bezoek. Het was simpelweg een aangenaam bezoek, waarbij ik slechts een glimp van één gezicht opving.',
        artistDescription: `De in Litouwen geboren kunstenares Kamilė Česnavičiūtė (1996) woont en werkt in Nederland. Kamilė studeerde na de middelbare school beeldende kunst aan de kunstacademie Minerva te Groningen. Na haar afstuderen legde Kamilė zich toe op het schilderen.Haar jonge carrière kent reeds een aantal bijzondere prestaties. In 2019 behoorde ze tot de 'Best of Graduates' tentoongesteld bij galerie Ron Mandos te Amsterdam. Vervolgens verkreeg ze een residentie in Het Resort. Ze won de Sluijters prijs voor schilderen en nam deel aan diverse groepstentoonstellingen en kunstenaarssamenwerkingen, zoals het corona-geïnspireerde online kunst- en muziekplatform @homescreen._ op Instagram.`,
        dimensions: '65 x 55 cm',
        details: 'Acryl op canvas',
    },
    {
        id: 10,
        lotNumber: 11,
        title: 'It wont fit',
        artist: 'Noreen Riepma',
        gallery: EGallery.GalerieLennie,
        image: itWontFit,
        startingBid: 680,
        description:
            'Dit werk geeft de worsteling en de paranoia van de mentale staat van de mens weer als een gedachtestroom en een continu overprikkeld brein. Naast de gedachtestroom, continue indrukken, gevoelens en ideeën die de menselijke hersenen dagelijks opslaan, bestaat er ook een intieme lichamelijke verbintenis. Soms lijkt alle informatie even niet in je hoofd te passen. Maar het besef dat er miljoenen mensen zijn die net als jij met vergelijkbare emoties, informatie en indrukken worstelen kan mensen ook weer samenbrengen, zowel mentaal als fysiek.',
        artistDescription:
            'Noreen Riepma is een kunstenares met museale potentie. Ze maakt op dit moment wandvullende houtskooltekeningen op grote vellen papier. De Nederlandse en Pakistaanse opvoeding van Noreen heeft een grote invloed gehad op haar werk. Ook het leven in verschillende landen zoals Engeland en Ierland heeft haar werk beïnvloed. Als kunstenaar wil ze seksuele taboes onder de aandacht brengen en doorbreken, belangrijke seksuele en gender gerelateerde kwesties vertegenwoordigen en de donkerste waarheden omtrent de menselijke mentale staat het onder ogen komen.',
        dimensions: '42 x 43 cm',
        details: 'Zwarte balpen inkt op papier',
    },
    {
        id: 11,
        lotNumber: 12,
        title: 'Affairs in Maakhaven',
        artist: 'Just Alex',
        gallery: EGallery.GalerieLennie,
        image: affairs,
        startingBid: 500,
        description: '',
        artistDescription: `Alex zoekt een middenweg in de lyrische spanning die heerst tussen enerzijds de niet-aflatende drang om betekenis te geven en anderzijds de even onverzettelijke aandrang van het toeval. Deze spanning is de vibratie of misschien beter gezegd de dissonantie die de alledaagse ervaring bepaalt. Op het moment dat Alex deze paradox weergeeft in een materieel proces - een beeld dat wordt geconfronteerd met de blik van een toeschouwer - vindt er een ontmoeting plaats met de 'liminaliteit'. Dit is het moment waarop een toeschouwer die een beeld zonder impliciete of expliciete betekenis bestudeert, komt tot een interpretatie.`,
        dimensions: '60 x 70 cm',
        details: 'Oil on canvas',
    },
    {
        id: 12,
        lotNumber: 13,
        title: 'Vader draagt zijn zoon in zijn armen',
        artist: 'Noreen Riepma',
        gallery: EGallery.GalerieLennie,
        image: vaderDraagtZoon,
        startingBid: 400,
        description:
            'De tekening laat een Afghaanse vluchteling zien op het strand.',
        artistDescription: `"Noreen Riepma is een kunstenares met museale potentie. Ze maakt op dit moment wandvullende houtskooltekeningen op grote vellen papier.
        De Nederlandse en Pakistaanse opvoeding van Noreen heeft een grote invloed gehad op haar werk. Ook het leven in verschillende landen zoals Engeland en Ierland heeft haar werk beïnvloed.
        Als kunstenaar wil ze seksuele taboes onder de aandacht brengen en doorbreken, belangrijke seksuele en gender gerelateerde kwesties vertegenwoordigen en de donkerste waarheden omtrent de menselijke mentale staat het onder ogen komen."`,
        dimensions: '21 x 29,7 cm',
        details: 'Potlood op papier',
    },
    {
        id: 13,
        lotNumber: 14,
        title: 'Spirit 4',
        artist: 'Jacobien de Korte',
        gallery: EGallery.GalerieLennie,
        image: spirit4,
        startingBid: 600,
        description:
            'De recent gemaakte serie “Spirit” is ontstaan om verhalen van anderen/ de afgebeelde op mijn eigen wijze uit te beelden. Allen staan op een punt in hun leven waarbij zij vragen stellen, keuzes of zelfs wending maken. Hiervoor is moed nodig. Door deze belangrijke gebeurtenissen vast te leggen in beeld, wil ik ze kracht geven. ',
        artistDescription:
            'Jacobien de Korte (1969) heeft grafische vormgeving gestudeerd aan de Academie van beeldende kunsten in Rotterdam (Willem de Kooning). Haar autonoom werk ontstaat vanuit persoonlijke gevoelens en ervaringen. Op verstilde, introverte wijze tracht ze begrippen uit te drukken als vervreemding, eenzaamheid, mysterie en melancholie. Deze zijn voor iedereen in meer of mindere mate herkenbaar. Met het streven naar wederzijdse herkenbaarheid hoopt ze troost en begrip te bieden en te vinden. Het printen van het werk op fine art papier is hierbij een belangrijk onderdeel van het proces.',
        dimensions: '60 x 40 cm',
        details: 'Fotografie op Dibond',
    },
    {
        id: 14,
        lotNumber: 15,
        title: 'Bra Strap',
        artist: 'Iris van der Harst',
        gallery: EGallery.GalerieLennie,
        image: braStrap,
        startingBid: 700,
        description:
            'Bra strap is een geïsoleerd moment van Iris haar eigen handen. Net als bij Horror Vacui heeft Iris in Bra strap geëxperimenteerd met de witruimte en of dit een rol kan spelen in de zeggingskracht van het werk.',
        artistDescription:
            'Iris van der Harst (1993) is een kunstschilder uit het noorden van Nederland die met olieverf de grenzen opzoekt binnen het zelfportretteren en creëren van stillevens. Het eren van de stilte van alledaagse momenten staat centraal, waarbij elementen van vervreemding, isolatie, intimiteit, kijken en bekeken worden het meest spreken. Nu meer dan ooit zijn we als mens aangewezen op onze capaciteit om te kunnen leven in isolatie. Met het aannemen van verschillende rollen - aan beide kanten van het doek - creëert Iris continu nieuwe perspectieven waarmee ze een werk op intrigerende wijze gestalte geeft.',
        dimensions: '60 x 50 cm',
        details: 'Olieverf op canvasboard',
    },
    {
        id: 15,
        lotNumber: 1,
        title: 'De kleur van psychotria op de Mont Blanc #8',
        artist: 'Eva Arakcheeva',
        gallery: EGallery.Artcatch,
        image: montBlanc8,
        startingBid: 800,
        description:
            '"De kleur van psychotria op de Mont Blanc" is de series van pseudo-realistische schilderij, waar Eva speelt met paradox en onverwachte visuele combinaties. Op het eerste gezicht lijken haar scenes gewoon, maar bij een nadere blik er wordt de vreemde incompatibilitiet gevonden. Boomschimmel in het midden van een jungle bos - is het niet een paradox? Net als in het leven, sommige dingen zijn niet altijd wat ze lijken te zijn.',
        artistDescription: `Eva Arakcheeva (geboren in 1989) is afgestudeerd aan het Moscow State Academic Art Institute in de naam van Surikov, Faculteit van Schilderkunst en de School van Hedendaagse Kunst "Vrije Workshops". Al tijdens haar studie begon ze haar artistieke en curatoriële praktijk. Ze heeft een tiental tentoonstelling gecureerd, waarvan er één werd opgenomen in een Parallel Programma van de 5de Internationale Biënnale voor Jonge Kunst in Moskou. Aan de basis van Eva's schilderkunst ligt conceptualisme, visueel gerealiseerd op een zeer realistische manier. Haar werken zijn vaak een mix van tegenstrijdige beelden, tekst, toespelingen en verborgen betekenissen.`,
        dimensions: '80 x 100 cm',
        details: 'Olieverf op canvas',
    },
    {
        id: 16,
        lotNumber: 2,
        title: 'De kleur van psychotria op de Mont Blanc #1',
        artist: 'Eva Arakcheeva ',
        gallery: EGallery.Artcatch,
        image: montBlanc1,
        startingBid: 350,
        description:
            '"De kleur van psychotria op de Mont Blanc" is de series van pseudo-realistische schilderij, waar Eva speelt met paradox en onverwachte visuele combinaties. Op het eerste gezicht lijken haar scenes gewoon, maar bij een nadere blik er wordt de vreemde incompatibilitiet gevonden. Een klein kwentsbaar vogeltje zadelt de bliksem - is het niet een paradox? Net als in het leven, sommige dingen zijn niet altijd wat ze lijken te zijn.',
        artistDescription: `Eva Arakcheeva (geboren in 1989) is afgestudeerd aan het Moscow State Academic Art Institute in de naam van Surikov, Faculteit van Schilderkunst en de School van Hedendaagse Kunst "Vrije Workshops". Al tijdens haar studie begon ze haar artistieke en curatoriële praktijk. Ze heeft een tiental tentoonstelling gecureerd, waarvan er één werd opgenomen in een Parallel Programma van de 5de Internationale Biënnale voor Jonge Kunst in Moskou. Aan de basis van Eva's schilderkunst ligt conceptualisme, visueel gerealiseerd op een zeer realistische manier. Haar werken zijn vaak een mix van tegenstrijdige beelden, tekst, toespelingen en verborgen betekenissen.`,
        dimensions: '30 x 25 cm',
        details: 'Olieverf op canvas',
    },
    {
        id: 17,
        lotNumber: 3,
        title: 'Untitled ',
        artist: 'crocodilePOWER',
        gallery: EGallery.Artcatch,
        image: crocodileHorizon,
        startingBid: 250,
        description: `De duo gaat verder met het verkennen van de meest fantastische scenario's van toekomstige ontwikkeling van de relatie van de mens met de natuur in het tijdperk van epische technologische vooruitgang.`,
        artistDescription: `crocodilePOWER is een Russisch kunstenaarsduo dat in 2011 werd opgericht door Peter Goloshchapov (geboren in 1982) en Oksana Simatova (geboren in 1979). Beiden zijn afgestudeerd aan de Stroganov Moskouse Staatsuniversiteit voor Kunst en Industrie (een van de oudste en belangrijkste hogere kunstinstellingen in Rusland). In 2012 voltooiden ze een stage aan het Central Saint Martins College of Art and Design in Londen. Samenwerken is voor beiden een nieuwe creatieve ervaring geworden en heeft een begin gemaakt met het bestuderen van de hoogtechnologische wereld van vandaag en de plaats van de mens daarin. crocodilePOWER creëert mythologieën ergens tussen fantasie en realiteit, tussen technologie en natuur.`,
        dimensions: '41 x 31 cm',
        details: 'Aquarel, aquarel potlood en marker op papier',
    },
    {
        id: 18,
        lotNumber: 4,
        title: 'Untitled #1',
        artist: 'Anastasia Dunaeva',
        gallery: EGallery.Artcatch,
        image: humizovo,
        startingBid: 200,
        description:
            'Deze schilderij is de onderzoek van de vorm, kleur en ruimte. Veel van de schilderijen op canvas van Anastassia vinden plek in haar street-art werken.',
        artistDescription: `Anastasia Dunaeva (geboren in 1998) is schilder en digitaal kunstenaar. Ze onderzoekt graffiti als medium en cultureel fenomeen. In haar straatpraktijk creëert ze plaatsgebonden abstracte schilderijen in de openbare ruimte, die het onderwerp zijn van haar verdere experimenten met representatie in digitaal formaat. Haar werk bevat kenmerken van street art zoals interactie met de ruimte, vergankelijkheid, marginaliteit en fysieke uitdagingen. Daarnaast vertoont haar praktijk duidelijke kenmerken van hedendaagse kunst. Zodoende kan het werk nauwkeuriger omschreven worden als "street painting". Anastasia is afgestudeerd in Art & Design aan de Higher School of Economics en is student aan het Instituut voor Hedendaagse Kunst in Moskou. Haar werken zijn te zien geweest in vele openbare ruimtes in Moskou. In 2021 lanceerde Electrozavod Gallery haar site-specific persoonlijke show.`,
        dimensions: '40 x 40 cm',
        details: 'Mixed media op canvas',
    },
    {
        id: 19,
        lotNumber: 5,
        title: 'Het verlaten eiland #1 ',
        artist: 'Mariia Ermolenko',
        gallery: EGallery.Artcatch,
        image: verlatenEiland,
        startingBid: 200,
        description:
            'Het project "Het verlaten eiland" die al in bepaalde plekken vertoont was, is gewijd aan de vernietiging van de geschiedenis, de efemeraliteit van de objecten uit het verleden we kunnen niet stoppen. Als we de architectuur herstellen, is het dan hetzelfde gebouw? Kan het nog steeds zijn naam dragen? Kunnen we er zeker van zijn dat het er net zo uitziet als de originele? Mariia reproduceert dit idee door een kopie te maken van de kopie van de foto totdat het beeld bijna volledig de gelijkenis van het origineel verliest. Het project omvat de oude reïneuze forten rond Kronstadt in St. Petersburg, gebouwd door Peter de Grote.',
        artistDescription: `Mariia Ermolenko (geboren in 1995) is beeldend kunstenaar en fotograaf. Ze zoekt naar magisch realisme in de wereld, dat vooral te vinden is in de natuur. Mariia's foto's zijn bijna vrij van enscenering, maar vereisen veel geduld en observatie op zoek naar het perfecte moment of de perfecte plek, vandaar dat veel van haar werken een langdurige uitputtende uitvoering vereisen. Oude heidense mythen en tradities van de Slaven, ecologische drama's, de schoonheid van het besneeuwde veld of kostbare visuele momenten van herinnering en andere thema's verbonden met de natuur en ons ware zelf in harmonieuze coëxistentie met het, trekken haar aandacht. In haar werk gebruikt ze verschillende technieken: grafiek, fotografie, installatie en beeldhouwkunst. Mariia is oprichtster en redactrice van het fotografietijdschrift Grandmama's Print. Ze woont en werkt in St. Petersburg.`,
        dimensions: '35 x 25 cm',
        details: 'Print op Hahnemuhle papier',
    },
    {
        id: 20,
        lotNumber: 6,
        title: 'Time & Being',
        artist: 'Valentin Korzhov',
        gallery: EGallery.Artcatch,
        image: beingTime,
        startingBid: 500,
        description:
            'Sculptuur in de setting van de eindeloze sneeuwvelden als weergave van Heideggers idee van het Niets — voor Valentin is het de beste manier om de essentie van de ware vorm te vatten. Gefascineerd door de ideeën van Aristoteles over chaos en orde, vindt Valentin vorm voor zijn sculpturen in de clusters van hemellichamen gevangen door de Hubble telescoop.',
        artistDescription: `Valentin Korzhov is een kunstenaar van de ruimte. Gefascineerd door Heideggers idee van het niets, plaatst Valentin zijn ongewone beeldhouwwerken in een neutrale natuurlijke omgeving om hun materialiteit te observeren in de omgeving van een bijna volledige leegte. Dit is, volgens de grote Duitse filosoof, "de enige toestand waarin de vorm zich kan openbaren." Zijn recente sculpturale projecten presenteren een visuele vertelling over de existentiële crises van de mensheid aan het einde van het postmodernistische tijdperk en tegelijkertijd over pogingen om een steunpunt te vinden in fundamentele filosofische en religieuze teksten. In zijn zeer psychologische figuratieve sculpturen speelt tekst een vitale rol, waarbij hij het idee van logos erft als een primaire eigenschap van een mens die vooruitloopt op zijn verschijning.`,
        dimensions: '18 x 62 cm',
        details: 'Print op Barite papier',
    },
    {
        id: 21,
        lotNumber: 7,
        title: 'Unterfuhrungam am Messedamm, Berlin',
        artist: 'Ekaterina Kovalenko',
        gallery: EGallery.Artcatch,
        image: Messedamm,
        startingBid: 100,
        description: `Kovalenko's kunst brengt enkele tradities van het amerikaans modernisme naar boven. De kunstenaar stelt een stedelijk landschap voor zonder de menselijke aanwezigheid en concentreert zich op de vormen en de geometrie van de plaats. Op deze manier wordt het schilderij bijna abstract, vol van licht en leegte.`,
        artistDescription: `Ekaterina Kovalenko (geboren in 1990) is afgestudeerd aan de Russische Academie voor Schilderkunst, Beeldhouwkunst en Architectuur in Moskou, met als specialisatie Beeldhouwkunst. Ekaterina werkt als solo kunstenaar en in duo's, voornamelijk gericht op het figuratieve aspect van de vorm, het vergroten van de kennis die de klassieke opleiding haar gaf samen met de jarenlange ervaring binnen het hedendaagse kunstveld. Als een op onderzoek gebaseerde kunstenares gebruikt ze sociale, ecologische, wetenschappelijke, mythische en alle andere bronnen die binnen haar bereik liggen, hoewel Kovalenko politieke manipulaties in haar artistieke taal probeert te vermijden. Ze verkiest te spreken via de vorm en haar lopende projecten appelleren aan de problemen van overbevolking en desillusie in het antropocentrisme.`,
        dimensions: '32 x 24 cm',
        details: 'Aquarel op papier',
    },
    {
        id: 22,
        lotNumber: 8,
        title: 'Polaroid dromen',
        artist: 'Maxim Ksuta',
        gallery: EGallery.Artcatch,
        image: polaroidDromen,
        startingBid: 600,
        description:
            '"Polaroid dromen" is het lopende project van Maxim, waarin hij creëert semi-fantastische landschapen en architectuur door tal van puntjes met de hand getekend op het oppervlak van een polaroid foto. Deze pseudo-herinneringen duiken op als verre gebeurtenissen die zo lang geleden gebeurden dat we niet meer kunnen begrijpen of ze werkelijk waren of onstonden ze uit de diepten van onze dromen. De set bevaat drie verschilende ingelijste polaroids en een passepartout.',
        artistDescription: `Maxim Ksuta (geboren in 1971) studeerde in 1994 af aan de Moscow State Aviation Technological University. Tegelijkertijd begon hij lessen te volgen in tekenen, aquarel grafiek en kabinetsschilderen. Maxim Ksuta is een transdisciplinair kunstenaar die werkt met gemengde media, waaronder grafiek, schilderkunst en fotografie. Hoewel Ksuta's foto's de toeschouwer formele, zichtbare en tastbare elementen laten zien, fixeren zijn werken niet louter de werkelijkheid of documenteren ze die. Zijn werken zijn grafisch en doen ons slechts denken aan vertrouwde beelden, objecten, stukjes natuur. Ksuta ontkent de objectiviteit van de fotocamera en hij breekt met de "mechanische reproductie". Zijn werken zijn volstrekt persoonlijk en vol unieke artistieke gebaren die worden gedragen door de zorgvuldige observatie en het proces, de metafysica van de observatie.`,
        dimensions: '30 x 21 cm (met passepartout) - een set van 3 stukken',
        details: 'Acrylinkt met isograph op polaroid',
    },
    {
        id: 23,
        lotNumber: 9,
        title: 'Samara',
        artist: 'Lota Lota (Svetlana Mamontova)',
        gallery: EGallery.Artcatch,
        image: lotaSamara,
        startingBid: 400,
        description: '',
        artistDescription: `Svetlana Mamontova (geboren in 1991) is een beeldend kunstenaar die zich voornamelijk richt op beeldhouwkunst. Ze studeerde af aan de Altai State Technical University vernoemd naar Lomonosov in 2008-2014 met een graad in Architectural Environment Design. In 2018 nam ze deel aan de Russische Reporter Zomerschool cursus over illustratie. Geboren en getogen in Barnaul, verhuisde ze in 2017 naar Moskou en begon te werken met keramiek. Haar werk kan worden omschreven als details en objecten die met een ontspannen blik zijn gejat, willekeurig gemengd, samengevoegd met dierbare of willekeurige herinneringen en flarden van gedachten, met een doel of gewoon voor de lol. "Ik ben gefascineerd door de mogelijkheid om driedimensionale, vooral gebruiksvoorwerpen te maken, door de tactiliteit van de ruwe klei en de textuur van het afgewerkte werk, door de mogelijkheid om tijdens het proces ergens over na te denken, een grapje te maken of ergens aan herinnerd te worden."`,
        dimensions: '36 x 22 x 16 cm',
        details: 'Keramiek, geglazuurd',
    },
    {
        id: 24,
        lotNumber: 10,
        title: 'Pass',
        artist: 'Nikolay Onischenko',
        gallery: EGallery.Artcatch,
        image: pass3,
        startingBid: 650,
        description:
            'Een kleine figuur staat naast een enorme berg op een sombere avond. Dit kunstwerk is vol van de vermanende kracht van de natuur, haar grote schaal die de mens overneemt. Onishenko portretteert de natuur vol mysteries, onmogelijk voor een menselijke geest om te bevatten, waardoor het adembenemend is, maar tegelijkertijd ook beklemmend.',
        artistDescription: `Nikolay Onischenko (geboren in 1979) studeerde af aan de Kunst- en Grafiekafdeling van de Pedagogische Staatsuniversiteit van Voronezj en het Instituut voor Hedendaagse Kunst van Moskou. Zijn werken zijn vaak een botsing van online en offline objecten. Onischenko kiest ervoor om de beelden van post-sovjet gebouwen, en landschappen van de hedendaagse steden en de ruige natuur in beeld te brengen. Hij gooit vaak bekende en vreemde voorwerpen door elkaar, om het begrip van de Ander verder uit te werken en te spelen met de perceptie van de toeschouwer. De abstracte, nogal vervreemdende objecten worden afgebeeld in een natuurlijk landschap. Onischenko's werken trekken de aandacht van de toeschouwer en laten hem opgaan in de wereld van de Ander.`,
        dimensions: `Ø 40 cm`,
        details: 'Acryl op canvas',
    },
    {
        id: 25,
        lotNumber: 11,
        title: 'Mist II',
        artist: 'Kristina Romanova',
        gallery: EGallery.Artcatch,
        image: mist2,
        startingBid: 250,
        description: `Romanova's foto is een melancholische, kalme compositie, vol lucht en ruimte. Er is geen actie of beweging, de wind waait niet - het voelt bijna alsof zelfs de tijd heeft stilgestaan. De mist scheidt de lagen van het beeld - de scherpste en de delen van het beeld die de kijker nauwelijks kan zien. Op deze manier lokt de mist je het beeld in, in deze leegte van de non-ruimte.`,
        artistDescription: `Kristina Romanova (geboren in 1989) is afgestudeerd aan het Moskouse Luchtvaartinstituut, de School voor Fotografie van het ITAR-TASS Fotoagentschap en de Russische Staatsuniversiteit voor Menswetenschappen (gespecialiseerd in Hedendaagse Fotografie). Ze voltooide ook de cursus Projectfotografie onder leiding van Wladislav Efimov aan de Rodchenko Moskouse School voor Fotografie en Multimedia. In haar werk gaat ze dieper in op de herinnering aan de fysieke ruimte - van verzadigd urbanisme tot tijdloze landschappen. Haar belangrijkste medium is fotografie als installatie. De kunstenares is deelnemer aan talrijke groepstentoonstellingen in Rusland, Duitsland en andere landen. Haar werken werden getoond tijdens de 5de Biënnale voor Jonge Kunst in Moskou, het Festival voor Jonge Kunst 20-30 en andere.`,
        dimensions: '45 x 60 cm. Editie 2/20',
        details: 'Print op Hahnemuhle papier',
    },
    {
        id: 26,
        lotNumber: 12,
        title: 'Venus',
        artist: 'Anna Samojlova',
        gallery: EGallery.Artcatch,
        image: venus,
        startingBid: 200,
        description: `Dit werk maakt deel uit van de nieuwe serie "Venus" van Samojlova, waarvan de eerste, de oudste beelden (sculpturen, objecten) van Venus de specifieke elementen hadden die de fysieke kenmerken van de vrouw benadrukten die in verband worden gebracht met het baren. Anna Samojlova reflecteert op die beeldtaal aan de hand van haar eigen recente ervaring met het moederschap.`,
        artistDescription: `Anna Samojlova onderzoekt de rol van de vrouw in de historische en culturele context van Rusland. Ze gaat in op de tradities, gewoonten en rituelen in de Russische cultuur die nauw verbonden zijn met folklore en heidendom. Feministische invloeden uit het verleden verbindt ze met de angsten en ervaringen van hedendaagse vrouwen in Rusland: ze onderzoekt de thema's huiselijk geweld, relatie met thuis, zwangerschap en moederschap. Ze probeert de elementen van traditionele ambachten - de textiele ornamenten, kleuren en materialen met hedendaagse artistieke praktijken te doen herleven.`,
        dimensions: '30 x 21 cm',
        details: 'Calico, natuurlijke kleurstoffen, linosnede, katoenen draden',
    },
    {
        id: 27,
        lotNumber: 13,
        title: 'Untitled',
        artist: 'Alexey Vassiliev',
        gallery: EGallery.Artcatch,
        image: vassilievUntitled,
        startingBid: 1000,
        description: `Dit portret op beton bevat een ander kunstwerk in zichzelf - een geschilderd interieur waar dit personage woont. De wens om iets dierbaars en heel persoonlijk te behouden leidde Alexey tot het idee een soort gedenkplaat te creëeren als een boodschap aan toekomstige generaties.`,
        artistDescription: `Alexey Vassiliev (geboren in 1980) is een autodidactisch kunstenaar wiens ongewone artistieke pad zich uitstrekt van wis- en natuurkundeleraar tot deelnemer aan talrijke biënnales en kunsttentoonstellingen in Rusland en het buitenland. Alexey heeft zijn herkenbare artistieke manier ontwikkeld die doet denken aan Japanse manga en Duits expressionisme, op de rand van grafiek, schilderkunst en literatuur. Voor Vassiliev is het onderzoek altijd een toeval, een botsing van het onverwachte. Hij weigert de standaard onderzoeksmethoden te gebruiken en volgt liever zijn persoonlijke ervaringen, wat zijn werken echt persoonlijk, irrationeel en situationeel maakt. Sommige van zijn werken krijgen de kenmerken van non-finito, de onafheid. Dit geeft de toeschouwer het gevoel van een open vorm, de zeggingskracht van elke verfstreek. Maar laten we ons niet in verwarring brengen: Vassilievs werken missen de artistieke vorm niet - zijn portretten zijn levendig, emotioneel gespannen en vol energie.`,
        dimensions: '37 x 28 cm',
        details: 'Acryl op concreet',
    },
    {
        id: 28,
        lotNumber: 14,
        title: 'Middag',
        artist: 'Alexander Velichko',
        gallery: EGallery.Artcatch,
        image: noon,
        startingBid: 400,
        description:
            'Alexander speelt graag met schaal in zijn werken, die vaak lijken op het idee van het metafysische in het banale, het hele universum in één klein alledaags ding. Net als in zijn schilderijen en grafische werken wordt water nogal eens voorgesteld als symbool van bezinning, wedergeboorte en zuivering. De wereldberoemde filmregisseur Andrey Tarkovski, naar wie Velichko vaak verwijst, zei vroeger: "Water is een mystieke substantie, waarvan elk molecuul erg fotogeniek is. Het kan een bepaalde actie overbrengen, een gevoel van variabiliteit, continuïteit van beweging."',
        artistDescription: `Alexander Velichko is afgestudeerd aan de Nationale Kunstacademie van Lviv (Oekraïne). Na enkele jaren als grafisch ontwerper te hebben gewerkt, wijdt hij zich nu volledig gewijd aan de schilderkunst. Velichko is gefascineerd met dit traditionele medium. Hij onderzoekt het vermogen om met schilderkunst zeer gevoelige boodschappen over te brengen in een hedendaagse context. De realistische manier waarop hij schildert is eerder bedoeld om de levendige beweging van het bestaan weer te geven dan om gedetailleerd de waarheid weer te geven. In Velichko's werken is altijd een kloof tussen schoonheid en verval; de stilte voor de storm is voelbaar. Alexey exposeerde eerder in museum- en galerieruimtes in Rusland, zoals het Staatsmuseum van Hedendaagse Geschiedenis van Rusland (Moskou), het Russisch Museum (St.Petersburg) en het Russisch Museum voor Decoratieve Kunst (Moskou).`,
        dimensions: '21 x 28 cm',
        details: 'Grafiet op papier',
    },
    {
        id: 29,
        lotNumber: 15,
        title: 'Untitled',
        artist: 'Ustina Yakovleva',
        gallery: EGallery.Artcatch,
        image: yakovlevaUntitled,
        startingBid: 600,
        description: `Dit grafische werk maakt gebruik van de gemeenschappelijke elementen van het werk van Ustina Yakovleva. Het is voor de kunstenares van vitaal belang zich bewust te zijn van de tijd, en daarom benadert zij de elementen zeer pedant en zorgvuldig, en vormt zij de biomorfe structuren, die doen denken aan de natuurlijke structuren die eeuwenlang op aarde hebben bestaan. Haar werk wordt een punt van meditatie voor zowel de kunstenaar als de toeschouwer.`,
        artistDescription: `Ustina Yakovleva (geboren in 1987) studeerde af aan de Moskouse Staats Pedagogische Universiteit (Grafische Faculteit, 2009) en in hetzelfde jaar aan het Instituut voor Hedendaagse Kunst, Moskou. Ustina's familiale wortels komen uit het Russische Noorden, waar haar grootmoeder en moeder werden geboren, en dit heeft zijn weerslag gevonden in haar keuze van media en heeft gediend als een eindeloze bron van inspiratie en ideeën. Ustina's oeuvre is zeer tactiel en presenteert een ingewikkelde mix van archaïsche noordelijke ambachten en hedendaagse kunst: haar kunstwerken combineren vaak traditioneel kralenwerk, naaldwerk samen met grafiek en fotografie. Haar kunst is tijdloos en staat ver boven de dagelijkse routine en politiek, het verhaal is zonder duidelijk onderwerp, terwijl het creatieve proces doet denken aan een heidens ritueel.`,
        dimensions: '20 x 20 cm',
        details: 'Inkt en acryl op canvas',
    },
];
