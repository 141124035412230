export enum EGallery {
    GalerieLennie = 'Galerie Lennie',
    Artcatch = 'Artcatch',
}

export interface IArtwork {
    id: number;
    artist: string;
    artistDescription: string;
    description: string;
    dimensions: string;
    gallery: EGallery;
    image: string;
    lotNumber: number;
    details: string;
    startingBid: number;
    title: string;
}
