import styled from 'styled-components';
import { breakpoints, colors } from '../../constants';
import { CatfishLogo } from '../../svg';

import { Button } from '../Button';
import { Circle } from '../Circle';

const TextContainer = styled.div``;

interface IProps {
    className?: string;
    onClick: () => void;
}

const BaseLanding = ({ className, onClick }: IProps) => (
    <div className={className}>
        <Circle color={colors.red} />
        <CatfishLogo />
        <TextContainer>
            <h2>VOORJAARSVEILING VAN HEDENDAAGSE KUNST</h2>
            <h3>22 maart 2022 - Utrecht</h3>
            <p>
                Catfish is de eerste kunstveiling van betaalbare kunst in
                Utrecht georganiseerd door Art Catch & Galerie Lennie. Het
                evenement bestaat uit een tentoonstelling, een wijn receptie en
                natuurlijk de spannende veiling zelf. De veiling vindt plaats in
                een prachtig historisch negentiende-eeuws gebouw in de
                Wittevrouwen buurt.
            </p>
        </TextContainer>
        <Button onClick={onClick}>MELD JE AAN</Button>
    </div>
);

export const Landing = styled(BaseLanding)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 60px;
    z-index: 1;

    ${TextContainer} {
        margin: 20px;
        width: 100%;
        max-width: 420px;
        text-align: center;
    }

    h2 {
        font-size: 24px;
        line-height: 1.1;
    }

    h3 {
        font-size: 24px;
        font-weight: 300;
    }

    ${CatfishLogo} {
        max-width: 1280px;
        margin: 40px 0;

        @media (${breakpoints.smallMin}) {
            margin: 40px 0;
        }
    }

    ${Button} {
        margin: 0 auto;
        margin-top: 40px;
    }

    ${Circle} {
        position: absolute;
        z-index: -1;
        transform: translate(0, -60%);
        width: 60vw;
        height: 60vw;
        max-width: 320px;
        max-height: 320px;
    }
`;
