import styled from 'styled-components';
import { Container } from '../Container';
import { SubscribeForm } from '../SubscribeForm';
import { Circle } from '../Circle';
import { colors } from '../../constants';

const Credits = styled.div``;

interface IProps {
    className?: string;
    formRef: React.RefObject<HTMLFormElement>;
}

const BaseFooter = ({ className, formRef }: IProps) => (
    <div className={className}>
        <Container>
            <h2>MELD JE AAN</h2>
            <h3>Vul hieronder je gegevens in</h3>
            <SubscribeForm formRef={formRef} />
            <Credits>
                Deze veiling wordt mogelijk gemaakt door{' '}
                <a
                    href="https://artcatch.art"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Art Catch
                </a>{' '}
                en{' '}
                <a
                    href="https://galerielennie.nl"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Galerie Lennie
                </a>
                .
            </Credits>
        </Container>
        <Circle color={colors.pink} />
    </div>
);

export const Footer = styled(BaseFooter)`
    position: relative;
    text-align: center;
    line-height: 1.2;
    padding: 20px 20px 40px;
    margin-top: 40px;
    z-index: 1;

    h3 {
        font-weight: 300;
    }

    ${SubscribeForm} {
        margin: 0 auto;
        margin-bottom: 40px;
    }

    ${Credits} {
        line-height: 1.5;
        max-width: 500px;
        margin: 0 auto;
    }

    ${Circle} {
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        // Transform into semicircle
        width: 300px;
        height: 150px;
        border-radius: 150px 150px 0px 0px;
    }
`;
